import { createRoot } from "react-dom/client";
import App from "../../map/app";

document.addEventListener("DOMContentLoaded", () => {
  const container = document.getElementById("leaflet-map") as HTMLElement;
  const root = createRoot(container);
  const className = container.getAttribute("data-className");
  root.render(<App className={className ?? ""} />);

  const form = document.querySelector("form[name=form]") as HTMLFormElement;

  document
    .querySelectorAll("form input[name=search_query] + input[type=button]")
    .forEach((button) => {
      button.previousElementSibling?.addEventListener("keydown", (e) => {
        if (e.key === "Enter") {
          e.preventDefault();
          e.stopPropagation();
          if (form) {
            form.submit();
          }
        }
      });

      button.addEventListener("click", (e) => {
        // find form and submit
        if (form) {
          form.submit();
        }
      });
    });
});
